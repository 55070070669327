import React from "react"
import Faqs from "../components/faq/faq"
import Header from "../components/header/Header"
import FooterNew from "../components/footer/FooterNew"
import { BrowserRouter } from "react-router-dom"

const FAQS = () => {
  return (
    <div className="body-container_1">
      <Header />
      <Faqs />
      <FooterNew />
    </div>
  )
}

export default FAQS
