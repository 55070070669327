import React, { useState } from "react"
import "./faq.css"
import { faqs } from "../../../site_data/faqspage.json"
const FAQS = () => {
  const [faqsState, setFaqsState] = useState(
    faqs.map(({ question, answer }, i) => {
      return {
        selected: i === 0 ? true : false,
        question,
        answer,
      }
    })
  )
  const manageAccordation = i => {
    setFaqsState(prev => {
      let currentSelection = null
      prev.forEach((faq, faqindex) => {
        if (faqindex !== i) {
          faq.selected = false
        }
      })
      prev[i].selected = !prev[i].selected
      return [...prev]
    })
  }
  return (
    <div className="faqs_container">
      <h3>Frequently asked questions</h3>
      <div className="faqs_tile_container">
        {faqsState.map(({ selected, question, answer }, i) => (
          <div key={"faq" + i.toString()} className="faqs_tile">
            <div className="faq_question">
              <p>{question}</p>
              <button onClick={() => manageAccordation(i)}>
                {selected ? "-" : "+"}
              </button>
            </div>
            {selected && (
              <div className="faq_answer">
                <p>{answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default FAQS
